import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results 6-8 weeks',
}

const questions: Array<Question> = [
  {
    question: 'Will it hurt?',
    answer:
      'A lash lift is not painful, but your eyes might water a little. First of all, a silicone pad is placed on your ' +
      'closed eyelids. Your lashes are then curled over the top, and a lifting lotion is applied, followed by a setting' +
      ' lotion to set the lift. The treatment finishes with a lash tint for extra definition. Lasts 6-8 weeks.',
  },
  {
    question: 'How does it work?',
    answer:
      "Think of a lash lift as a perm for your lashes: it doesn't add length like extensions, but the curl makes your " +
      'lashes look longer. Most lashes grow out, not up, so curling upwards opens up your eyes for a wide-eyed look. ' +
      'After tinting, your lashes will look thicker, darker and longer-looking. However, ' +
      'if you have short stubby lashes, stick with extensions.',
  },
]

const LashLift: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Lash Lift and Tint - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Lush lift and Tint" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                Long, dark, luscious lashes are the ultimate symbol of glamour
                and feminity. It&lsquo;s time to start fluttering your lashes.
              </p>
              <p>
                But are you looking for a low-maintenance alternative to lash
                extensions? Are you fed up with the damage and upkeep? If you
                want a more natural look, a lash lift will curl and lift your
                lashes for weeks. Imagine waking up every morning with dark,
                luscious lashes ready to go.
              </p>
              <p>
                At Ada Aesthetics, we understand the power of dark, seductive
                lashes. We specialise in eyelash treatments and believe that
                eyelash care is an essential part of your grooming routine, just
                as important as skincare and haircare.
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default LashLift

export const pageQuery = graphql`
  query LashLift {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
